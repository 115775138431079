<template>
  <div>
    <h3
      class="card-title border-b"
    >
      密码管理
    </h3>
    <b-form
      class="mt-3"
      @submit.prevent
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="现在的密码"
            label-for="h-first-name"
            label-cols-md="2"
          >
            <b-form-input
              id="h-email"
              v-model="form.old_password"
              placeholder="现在的密码"
            />
            <small
              v-if="!form.old_password && form.new_password && form.new_password_repeat"
              style="color:red;"
            >密码不能为空，请输入</small>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="新密码"
            label-for="h-email"
            label-cols-md="2"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="basic-password1"
                v-model="form.new_password"
                :type="passwordFieldType"
                placeholder="新密码"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-row
          v-if="form.new_password"
          class="mb-2"
        >
          <b-col
            v-for="item in verifyList"
            :key="item.id"
            cols="12"
            class="d-flex justify-content-center"
          >
            <div
              class="d-flex justify-content-start align-items-center"
              style="width:41%"
            >
              <feather-icon
                v-if="!item.isVerify"
                icon="XIcon"
                size="20"
                style="color: red"
                class="cursor-pointer"
              />
              <feather-icon
                v-else
                icon="CheckIcon"
                size="20"
                style="color: green"
                class="cursor-pointer"
              />
              <span :style="{color: !item.isVerify ? 'red' : 'green'}">{{ item.text }}</span>
            </div>
          </b-col>
        </b-row>
        <b-col cols="12">
          <b-form-group
            label="重复新密码"
            label-for="h-password"
            label-cols-md="2"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="basic-password1"
                v-model="form.new_password_repeat"
                :type="passwordFieldType2"
                placeholder="重复新密码"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon2"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility2"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="!isNewPsdrVerify"
              style="color:red;"
            >密码输入不一致，请检查</small>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col
          offset-md="5"
          class="mt-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="handleVerify"
          >
            更改密码
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="handleReset"
          >
            重置
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        old_password: '',
        new_password: '',
        new_password_repeat: '',
      },
      passwordFieldType2: 'password',
    }
  },
  computed: {
    verifyList({ form }) {
      return [{
        id: 1,
        isVerify: !(form.new_password.length < 8),
        text: 'Must be at least 8 characters long(最少8个字符)',
      }, {
        id: 2,
        isVerify: !!/(?=.*[A-Z])/.test(form.new_password),
        text: 'Must contain an uppercase letter(至少包含一个大写字母)',
      }, {
        id: 3,
        isVerify: !!/(?=.*[a-z])/.test(form.new_password),
        text: 'Must contain a lowercase letter(至少包含一个小写字母)',
      }, {
        id: 4,
        isVerify: !!/(?=.*\d)/.test(form.new_password),
        text: 'Must contain a number(至少包含一个数字)',
      }, {
        id: 5,
        isVerify: !!/(?=.*[!@#$%^&*? ])/.test(form.new_password),
        text: 'Must contain a special character(至少包含特殊字符)',
      }]
    },
    isNewPsdrVerify({ form }) {
      return form.new_password === form.new_password_repeat
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIcon2() {
      return this.passwordFieldType2 === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordVisibility2() {
      this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password'
    },
    handleReset() {
      // eslint-disable-next-line
      for (const k in this.errors) {
        this.errors[k] = ''
      }
    },
    handleVerify() {
      const isVerify = this.verifyList.every(item => item.isVerify)
      if (isVerify && this.form.old_password && this.isNewPsdrVerify) {
        this.handleSubmit()
      } else {
        this.$bvModal
          .msgBoxConfirm('校验未通过，请检查', {
            cancelVariant: 'outline-danger',
            centered: true,
            buttonSize: 'sm',
          })
      }
    },
    async handleSubmit() {
      try {
        const value = localStorage.getItem('userInfo')
        const userInfo = JSON.parse(value)
        const params = {
          agent_id: userInfo.id,
          ...this.form,
        }
        const data = await this.$api.resetPassword(params)
        if (data.code === 200) {
          this.$bvToast.toast('修改成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        } else {
          this.$bvToast.toast(data.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
