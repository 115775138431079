<template>
  <div>
    <b-card>
      <div class="border-b d-flex justify-content-between align-items-center">
        <h3 class="card-title">
          自提设置
        </h3>
        <b-button
          v-if="!isEditLift"
          variant="relief-primary"
          @click="isEditLift = true"
        >
          编辑
        </b-button>
        <b-button
          v-else
          variant="relief-success"
          @click="handleSubmit"
        >
          {{ isLoadingLift ? '保存中...' : '保存' }}
        </b-button>
      </div>
      <div class="mt-1">
        <div class="d-flex justify-content-start align-items-center">
          <div class="left">
            <span class="text-right">单个包裹接受的最大重量</span>
            <span
              class="tips yellow"
            >(最大为25)</span>
          </div>
          <b-input-group
            append="kg"
            class="input-group-merge ml-4"
            style="width:30%"
          >
            <b-form-input
              v-model="form.weight"
              style="height:38.42px"
              type="number"
              :disabled="!isEditLift"
              @blur="handleInputBlur('weight')"
            />
          </b-input-group>
          <span
            v-if="error.weight"
            class="ml-2 red"
          >您设置的单位超出限制了，请修改</span>
        </div>
        <div class="d-flex justify-content-start align-items-center mt-2">
          <div class="left">
            <span class="text-right">单个包裹接受的最长边</span>
            <span
              class="tips yellow"
            >(最大为120)</span>
          </div>
          <b-input-group
            append="cm"
            class="input-group-merge ml-4"
            style="width:30%"
          >
            <b-form-input
              v-model="form.side"
              style="height:38.42px"
              :disabled="!isEditLift"
              type="number"
              @blur="handleInputBlur('side')"
            />
          </b-input-group>
          <span
            v-if="error.side"
            class="ml-2 red"
          >您设置的单位超出限制了，请修改</span>
        </div>
        <div class="d-flex justify-content-start align-items-center mt-2">
          <div class="left">
            <span class="text-right">单个包裹接受的三边之和</span>
            <span
              class="tips yellow"
            >(最大为200)</span>
          </div>
          <b-input-group
            append="cm"
            class="input-group-merge ml-4"
            style="width:30%"
          >
            <b-form-input
              v-model="form.total"
              type="number"
              :disabled="!isEditLift"
              style="height:38.42px"
              @blur="handleInputBlur('total')"
            />
          </b-input-group>
          <span
            v-if="error.total"
            class="ml-2 red"
          >您设置的单位超出限制了，请修改</span>
        </div>
        <div class="d-flex justify-content-start mt-4">
          <span class="left text-right">自提备注信息</span>
          <b-form-textarea
            id="textarea-default"
            v-model="form.memo"
            placeholder="Textarea"
            rows="5"
            style="width:50%"
            class="ml-4"
          />
        </div>
        <div
          v-if="false"
          class="d-flex justify-content-start align-items-center mt-4 mb-2"
        >
          <span class="left text-right">指定会员</span>
          <b-form-input
            style="width:30%"
            class="ml-4"
          />
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="border-b d-flex justify-content-between align-items-center">
        <div class="card-title">
          营业时间（UTC+11）
        </div>
        <b-button
          v-if="!isEditBt"
          variant="relief-primary"
          @click="isEditBt = true"
        >
          编辑
        </b-button>
        <b-button
          v-else
          variant="relief-success"
          @click="handleSaveInfo"
        >
          保存
        </b-button>
      </div>
      <b-dropdown
        id="dropdown-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :text="btDropdownText"
        variant="success"
        class="mt-1"
      >
        <b-dropdown-item @click="btDropdownType = 1">
          有固定营业时间
        </b-dropdown-item>
        <b-dropdown-item @click="btDropdownType = 2">
          无固定营业时间
        </b-dropdown-item>
      </b-dropdown>
      <b-form-input
        v-if="btDropdownType === 2"
        v-model="businessHoursMark"
        :disabled="!isEditBt"
        class="mt-2"
        placeholder="设置无固定营业时间，可以显示在短信上"
        style="height:38.42px"
      />
      <b-overlay
        v-if="btDropdownType === 1"
        :show="overlayShow"
        rounded="sm"
        class="mb-2"
      >
        <div class="d-flex justify-content-between align-items-start mt-2">
          <div
            class="mt-1"
            style="width:13%"
          >
            <span>周一</span>
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-form-checkbox
                v-model="businessHours[0].selected"
                :disabled="!isEditBt"
                :value="`closed${businessHours[0].weekday}`"
              />
              <span style="margin-bottom:4px;">Closed</span>
            </div>
            <div v-if="businessHours[0].selected && !businessHours[0].selected.length">
              <b-form-timepicker
                v-model="businessHours[0].open_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
              <b-form-timepicker
                v-model="businessHours[0].close_time1"
                class="mt-1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
            </div>
          </div>
          <div
            class="mt-1"
            style="width:13%"
          >
            <span>周二</span>
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-form-checkbox
                v-model="businessHours[1].selected"
                :disabled="!isEditBt"
                :value="`closed${businessHours[1].weekday}`"
              />
              <span style="margin-bottom:4px;">Closed</span>
            </div>
            <div v-if="businessHours[1].selected && !businessHours[1].selected.length">
              <b-form-timepicker
                v-model="businessHours[1].open_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
              <b-form-timepicker
                v-model="businessHours[1].close_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
                class="mt-1"
              />
            </div>
          </div>
          <div
            class="mt-1"
            style="width:13%"
          >
            <span>周三</span>
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-form-checkbox
                v-model="businessHours[2].selected"
                :disabled="!isEditBt"
                :value="`closed${businessHours[2].weekday}`"
              />
              <span style="margin-bottom:4px;">Closed</span>
            </div>
            <div v-if="businessHours[2].selected && !businessHours[2].selected.length">
              <b-form-timepicker
                v-model="businessHours[2].open_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
              <b-form-timepicker
                v-model="businessHours[2].close_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
                class="mt-1"
              />
            </div>
          </div>
          <div
            class="mt-1"
            style="width:13%"
          >
            <span>周四</span>
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-form-checkbox
                v-model="businessHours[3].selected"
                :disabled="!isEditBt"
                :value="`closed${businessHours[3].weekday}`"
              />
              <span style="margin-bottom:4px;">Closed</span>
            </div>
            <div v-if="businessHours[3].selected && !businessHours[3].selected.length">
              <b-form-timepicker
                v-model="businessHours[3].open_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
              <b-form-timepicker
                v-model="businessHours[3].close_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
                class="mt-1"
              />
            </div>
          </div>
          <div
            class="mt-1"
            style="width:13%"
          >
            <span>周五</span>
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-form-checkbox
                v-model="businessHours[4].selected"
                :disabled="!isEditBt"
                :value="`closed${businessHours[4].weekday}`"
              />
              <span style="margin-bottom:4px;">Closed</span>
            </div>
            <div v-if="businessHours[4].selected && !businessHours[4].selected.length">
              <b-form-timepicker
                v-model="businessHours[4].open_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
              <b-form-timepicker
                v-model="businessHours[4].close_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
                class="mt-1"
              />
            </div>
          </div>
          <div
            class="mt-1"
            style="width:13%"
          >
            <span>周六</span>
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-form-checkbox
                v-model="businessHours[5].selected"
                :disabled="!isEditBt"
                :value="`closed${businessHours[5].weekday}`"
              />
              <span style="margin-bottom:4px;">Closed</span>
            </div>
            <div v-if="businessHours[5].selected && !businessHours[5].selected.length">
              <b-form-timepicker
                v-model="businessHours[5].open_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
              <b-form-timepicker
                v-model="businessHours[5].close_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
                class="mt-1"
              />
            </div>
          </div>
          <div
            class="mt-1"
            style="width:13%"
          >
            <span>周日</span>
            <div class="d-flex justify-content-start align-items-center mt-1">
              <b-form-checkbox
                v-model="businessHours[6].selected"
                :disabled="!isEditBt"
                :value="`closed${businessHours[6].weekday}`"
              />
              <span style="margin-bottom:4px;">Closed</span>
            </div>
            <div v-if="businessHours[6].selected && !businessHours[6].selected.length">
              <b-form-timepicker
                v-model="businessHours[6].open_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
              />
              <b-form-timepicker
                v-model="businessHours[6].close_time1"
                locale="en"
                placeholder="选择时间"
                :disabled="!isEditBt"
                class="mt-1"
              />
            </div>
          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BFormTextarea, BButton, BInputGroup, BOverlay, BFormCheckbox, BFormTimepicker, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BFormInput,
    BFormTextarea,
    BButton,
    BInputGroup,
    BOverlay,
    BFormCheckbox,
    BFormTimepicker,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    agentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        memo: '',
        total: 0,
        side: 0,
        weight: 0,
      },
      error: {
        total: false,
        side: false,
        weight: false,
      },
      businessHours: [{
        id: 0,
        weekday: 'monday',
        open_time1: '',
        close_time1: '',
        closedmonday: false,
      },
      {
        id: 0,
        weekday: 'tuesday',
        open_time1: '',
        close_time1: '',
        closedtuesday: false,
      },
      {
        id: 0,
        weekday: 'wednesday',
        open_time1: '',
        close_time1: '',
        closedwednesday: false,
      },
      {
        id: 0,
        weekday: 'thursday',
        open_time1: '',
        close_time1: '',
        closedthursday: false,
      },
      {
        id: 0,
        weekday: 'friday',
        open_time1: '',
        close_time1: '',
        closedfriday: false,
      },
      {
        id: 0,
        weekday: 'saturday',
        open_time1: '',
        close_time1: '',
        closedsaturday: '',
      },
      {
        id: 0,
        weekday: 'sunday',
        open_time1: '',
        close_time1: '',
        closedsunday: '',
      }],
      isEditBt: false,
      overlayShow: false,
      isEditLift: false,
      isLoadingLift: false,
      btDropdownType: 1,
      businessHoursMark: '',
    }
  },
  computed: {
    btDropdownText() {
      if (this.btDropdownType === 1) {
        return '有固定营业时间'
      }
      return '无固定营业时间'
    },
  },
  watch: {
    agentData: {
      handler(val) {
        if (val) {
          // eslint-disable-next-line
          for (const k in this.form) {
            this.form[k] = val[k]
            this.form.weight = val.max_weight
            this.form.total = val.side_total
            this.form.side = val.max_side
          }
        }
        if (val.business_time && val.business_time.length > 0) {
          this.businessHours = val.business_time.map(item => ({
            id: item.id,
            weekday: item.weekday,
            open_time1: item.open_time1,
            close_time1: item.close_time1,
            selected: (!item.open_time1 || !item.close_time1) ? [`closed${item.weekday}`] : [],
          }))
        }
        this.businessHoursMark = val.business_time_description
      },
      immediate: true,
    },
    form: {
      handler() {
        this.handleInputVerify()
      },
      deep: true,
    },
  },
  methods: {
    handleInputBlur(type) {
      this.form[type] = parseInt(this.form[type], 10)
    },
    handleInputVerify() {
      if (this.form.weight >= 0 && this.form.weight <= 25) {
        this.error.weight = false
      } else {
        this.error.weight = true
      }
      if (this.form.side >= 0 && this.form.side <= 120) {
        this.error.side = false
      } else {
        this.error.side = true
      }
      if (this.form.total >= 0 && this.form.total <= 200) {
        this.error.total = false
      } else {
        this.error.total = true
      }
    },
    async handleSubmit() {
      try {
        if (this.error.weight || this.error.side || this.error.total) {
          this.$bvModal
            .msgBoxConfirm('请填写合适的单位', {
              cancelVariant: 'outline-danger',
              centered: true,
              buttonSize: 'sm',
            })
          return
        }
        const value = localStorage.getItem('userInfo')
        const userInfo = JSON.parse(value)
        const params = {
          agent_id: userInfo.id,
          max_weight: Number(this.form.weight),
          max_side_length: Number(this.form.side),
          total_side_length: Number(this.form.total),
          memo: this.form.memo,
          business_time_description: this.businessHoursMark,
        }
        this.isLoadingLift = true
        const data = await this.$api.modifyAgentInfo(params)
        if (data.code === 200) {
          this.isLoadingLift = false
          this.isEditLift = false
          this.isEditBt = false
          this.$emit('update')
          this.$bvToast.toast('修改成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        } else {
          this.isLoadingLift = false
          this.$bvToast.toast(data.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (error) {
        this.isLoadingLift = false
        console.log('error', error)
      }
    },
    async handleSaveInfo() {
      try {
        if (this.btDropdownType === 1) {
          this.businessHours = this.businessHours.map(item => ({
            ...item,
            open_time1: item.selected.length > 0 ? '' : item.open_time1.substring(0, 5),
            close_time1: item.selected.length > 0 ? '' : item.close_time1.substring(0, 5),
            agent_id: this.agentData.id,
          }))
          this.overlayShow = true
          const data = await this.$api.updateAgentBusinessTime({ business_time: this.businessHours })
          this.overlayShow = false
          if (data.code === 200) {
            this.isEditBt = false
            this.$bvToast.toast('修改成功', {
              title: '提示',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-center',
            })
            this.$emit('update')
          } else {
            this.$bvToast.toast(data.message, {
              title: '提示',
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-center',
            })
          }
        } else {
          this.handleSubmit()
        }
      } catch (error) {
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-right{
  font-size: 18px;
}
.left{
  width:20%;
  text-align: right;
}
.tips{
  font-size: 18px;
  display: block;
}
.yellow{
  color:#FFAF2A;
}
.red{
  color:#ff0000;
}
</style>
