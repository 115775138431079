<template>
  <b-tabs
    vertical
    nav-wrapper-class="nav-vertical"
  >
    <b-tab
      active
      title="基础信息"
    >
      <b-card class="basic-card">
        <basic-info
          v-if="agentData.name"
          :agentData="agentData"
          @update="initState"
        />
        <div
          v-if="isLoading"
          class="d-flex justify-content-center align-items-center loading-box"
        >
          <b-spinner
            variant="primary"
            class="mr-1"
            type="grow"
          />
          <div class="loading-text">
            Loading...
          </div>
        </div>
      </b-card>
    </b-tab>
    <b-tab title="密码">
      <b-card>
        <password />
      </b-card>
    </b-tab>
    <b-tab title="自提设置">
      <lift-set
        :agentData="agentData"
        @update="initState"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BCard, BSpinner,
} from 'bootstrap-vue'
import BasicInfo from './components/basicInfo.vue'
import Password from './components/password.vue'
import LiftSet from './components/liftSet.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BasicInfo,
    Password,
    LiftSet,
    BSpinner,
  },
  data() {
    return {
      agentData: {},
      isLoading: false,
    }
  },
  created() {
    this.initState()
  },
  methods: {
    async initState() {
      try {
        const value = localStorage.getItem('userInfo')
        const userInfo = JSON.parse(value)
        this.isLoading = true
        const { data } = await this.$api.agentInfo({ agent_id: userInfo.id })
        this.agentData = data
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.basic-card{
  min-height: 500px;
}
.loading-box{
  margin-top: 20%;
}
.loading-text{
  font-size: 26px;
}
</style>
