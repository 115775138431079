<template>
  <div>
    <h3
      class="card-title border-b"
    >
      基础信息
    </h3>
    <h5 class="mt-2 fs-18 fw-bolder">
      代理点名称
    </h5>
    <div class="mt-1">
      {{ agentData.name }}
    </div>
    <hr>
    <h5 class="mt-2 d-flex justify-content-between align-items-center">
      <span class="fs-18 fw-bolder">代理点地址</span>
      <span class="modify-address">请联系管理员修改地址</span>
    </h5>
    <div class="mt-1 d-flex justify-content-start align-items-center">
      <span class="address-left text-right">Address:</span>
      <span class="address-right ml-1">{{ agentData.address }}</span>
    </div>
    <div class="mt-1 d-flex justify-content-start align-items-center">
      <span class="address-left text-right">City:</span>
      <span class="address-right ml-1">{{ agentData.city }}</span>
    </div>
    <div class="mt-1 d-flex justify-content-start align-items-center">
      <span class="address-left text-right">State:</span>
      <span class="address-right ml-1">{{ agentData.state }}</span>
    </div>
    <div class="mt-1 d-flex justify-content-start align-items-center">
      <span class="address-left text-right">Postcode:</span>
      <span class="address-right ml-1">{{ agentData.postcode }}</span>
    </div>
    <div class="mt-1 d-flex justify-content-start align-items-center">
      <span class="address-left text-right">Phone:</span>
      <span class="address-right ml-1">{{ agentData.phone }}</span>
    </div>
    <div class="mt-1 d-flex justify-content-start align-items-center">
      <span class="address-left text-right">Country:</span>
      <span class="address-right ml-1">{{ agentData.country }}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    agentData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.modify-address{
  font-size: 18px;
  color: #FFAF2A;
}
.address-left{
  width:8%;
  font-size: 18px;
}
.address-right{
  font-size: 18px;
}
.plus-icon{
  font-size: 18px;
  color: #FFAF2A;
  cursor: pointer;
}
</style>
